<template>
  <div class="maxBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- 新闻中心 -->
    <div class="boxContentNews">
      <div class="newsCenter">
        <!-- 导航条区域 -->
        <div class="newsBar">
          <div>首页/</div>
          <div>新闻中心</div>
        </div>
        <!-- 新闻主体区域 -->
        <div class="NewSubject">
          <!-- 左侧文字区域 -->
          <div class="NewSubjectLeft">
            <div class="NewSubjectTitle">
             {{ news.title }}
            </div>
            <div class="NewSubjectYear">
              <div class="NewSubjectFrom">来自：{{ news.source }}</div>
              <div class="NewSubjectdate">{{ news.createTime && utils.formatDate(news.createTime) }}</div>
            </div>
            <div class="news-content" v-html="news.content">
            </div>
          </div>
          <!-- 右侧图文区域 -->
          <div class="NewSubjectRight">
            <div class="solution">
              <div class="solutionTitle" @click="toSolution">
                解决方案
              </div>
              <div @click="goLink('local')" class="curr-point">
                <div class="solutionContent">
                  <img src="../../assets/homePageImg/two-2.png" alt="" />
                </div>
                <div class="solutionSolve">
                  <div class="solutionSolveLeft">代客交易解决方案</div>
                  <div class="solutionSolveRight">
                    <img src="../../assets/news/recommend_more@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="platformServices">
              <div class="platformServicesTitle">平台服务</div>
              <div @click="goLink('solution')" class="curr-point">
                <div class="platformServicesContent">
                  <img
                          src="../../assets/homePageImg/platform_ser_qkl_01@2x.png"
                          alt=""
                  />
                </div>
                <div class="solutionSolve">
                  <div class="solutionSolveLeft">区块链金融服务平台</div>
                  <div class="solutionSolveRight">
                    <img src="../../assets/news/recommend_more@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部公共区域 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/public/NavBar'
import FooterAll from '@/components/public/footerAll'
import { getNewsDetail } from '@/api/index'
export default {
  data() {
    return {
      activeIndex: '',
      newsId: this.$route.query.newsId,
      news: {}
    }
  },
  components: {
    Navbar,
    FooterAll
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail(){
      getNewsDetail({
        id: this.newsId
      }).then(res =>{
        this.news = res.data.result
      })
    },
    goLink(hash) {
      this.$router.push(hash)
    },
    toSolution () {
      this.$router.push({
        path: '/solution',
        query: {
          id: '1',
        },
      })
    }
  }
}
</script>
<style lang="scss" >
 .news-content {
   width: 820px;
   font-size: 16px;
   font-weight: 400;
   color: #181818;
   line-height: 26px;
   padding-bottom: 48px;
   img {
     width:652px;
     margin-left: 84px;
     margin-top: 32px;
     margin-bottom: 32px;
   }
 }
</style>
<style lang="scss" scoped>
@import './news.scss';
  .NewSubjectdate{
    font-size: 14px;
    font-weight: 400;
    color: #181818;
    line-height: 20px;
  }
  .NewSubjectFrom{
    font-size: 14px;
    font-weight: 400;
    color: #181818;
    line-height: 20px;
  }
</style>
